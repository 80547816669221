import styles from "./Footer.module.css";

import Link from "next/link";
import { useRouter } from "next/router";
import {
  FaFacebook as FacebookIcon,
  FaInstagram as InstagramIcon,
  FaPinterest as PinterestIcon,
  FaTwitter as TwitterIcon,
  FaYoutube as YoutubeIcon,
} from "react-icons/fa";
import {
  MdBusinessCenter as BusinessIcon,
  MdQuestionAnswer as QuestionsIcon,
} from "react-icons/md";
import AcceptedPayments from "./AcceptedPayments";
import FooterNavigation from "./FooterNavigation";
import FooterTA from "./FooterTA";
import PartnerList from "./Partner";

export default function Footer() {
  const router = useRouter();
  const isCheckout =
    router.pathname === "/bookings/[id]" ||
    router.pathname === "/checkout/[id]" ||
    router.pathname === "/payments/[id]"
      ? true
      : false;

  const hideFooter =
    isCheckout ||
    router.pathname === "/guide/[guideId]/sign-contracts" ||
    router.pathname === "/guide/sign-contracts-success";

  return (
    <section style={hideFooter ? { display: "none" } : null}>
      <FooterTA />
      <FooterNavigation />

      <footer>
        <div className={styles.AppFooter}>
          <div
            className={styles.AppFooterInner}
            style={{
              background: "linear-gradient(90deg, #f44336, #e91e63)",
            }}
          >
            <div className={styles.footAbout}>
              <h6>Magical Trip</h6>

              <ul>
                <li>
                  <Link href="/about-us" className={styles.AppFooterTextLink}>
                    About Us
                  </Link>
                </li>
                <li>
                  <a
                    href="https://media.magical-trip.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.AppFooterTextLink}
                  >
                    Our Blog
                  </a>
                </li>
                <li>
                  <Link
                    href="/terms-of-service"
                    className={styles.AppFooterTextLink}
                  >
                    Terms of Service
                  </Link>
                </li>
                <li>
                  <Link
                    href="/privacy-policy"
                    className={styles.AppFooterTextLink}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    className={styles.AppFooterTextLink}
                    href="/external-transmission-rule"
                  >
                    External Transmission Rule
                  </Link>
                </li>
                <li>
                  <Link
                    href="/recruit?ref=footer"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.AppFooterTextLink}
                  >
                    Become a MagicalTrip guide
                  </Link>
                </li>
                <li>
                  <Link href="/faq" className={styles.AppFooterTextLink}>
                    Frequently Asked Questions
                  </Link>
                </li>
              </ul>
            </div>
            <div className={styles.AppFooterColumns}>
              <div className={styles.AppFooterCol}>
                <div>
                  <h6 className={styles.FooterHeading}>Social Network</h6>
                  <div>
                    <a
                      href="https://www.instagram.com/magicaltripcom/"
                      title="Instagram"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <InstagramIcon />
                    </a>
                    <a
                      href="https://www.facebook.com/magicaltripcom"
                      title="Facebook"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <FacebookIcon />
                    </a>

                    <a
                      href="https://www.youtube.com/channel/UC1CCKv3_R564PUJH3o2RAGQ"
                      title="Youtube"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <YoutubeIcon />
                    </a>
                    <a
                      href="https://twitter.com/magicaltripcom"
                      title="Twitter"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <TwitterIcon />
                    </a>

                    <a
                      href="https://www.pinterest.com/magicaltripcom"
                      title="Pinterest"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <PinterestIcon />
                    </a>
                  </div>

                  <h6 className={styles.FooterHeading}>As Featured On</h6>

                  <PartnerList />
                  <h6 className={styles.FooterHeading}>Ways You Can Pay</h6>
                  <AcceptedPayments />
                </div>
              </div>

              <div className={styles.AppFooterCol}>
                <h6 className={styles.FooterHeading}>Our Popular Tours</h6>

                <div className={styles.AppFooterTextLinks}>
                  <div>
                    <b>Tokyo</b>
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/product/59afbefb-b0d9-4e10-9273-b6c59b81e345?ref=footer"
                        >
                          Tokyo Bar Hopping
                        </a>
                      </li>

                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/product/e8a26b4b-898e-4e85-bdc2-1fd285dd06ff?ref=footer"
                        >
                          Tokyo Foodie Tour
                        </a>
                      </li>

                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/product/86a08414-88c3-44cf-8111-cc3895896f45?ref=footer"
                        >
                          Asakusa Walking Tour
                        </a>
                      </li>

                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/product/ec6f853d-0797-4ebe-b398-8bf928bfb3ec?ref=footer"
                        >
                          Tsukiji Fish Market Tour
                        </a>
                      </li>

                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/product/1e7c17b3-2fcb-4202-a97f-3b48eab4c181?ref=footer"
                        >
                          5-hour Tokyo Bike Tour
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <b>Kyoto</b>
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/product/cd7ede5a-72cb-4204-858a-52be444dc1ff?ref=footer"
                        >
                          Kyoto Bar Hopping
                        </a>
                      </li>

                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/product/ff345d77-8741-4669-aef1-e0d979c2361f?ref=footer"
                        >
                          Kyoto Foodie Tour
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/product/b0423202-29dd-4399-b047-9c6a0ee31ea5?ref=footer"
                        >
                          Nishiki Market Tour
                        </a>
                      </li>

                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/product/5375d9fa-7def-4938-b006-b97a44a4bd2e?ref=footer"
                        >
                          Fushimi Inari Tour
                        </a>
                      </li>

                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/product/f3520658-af13-4f1b-a85a-7b75ceb5ab21?ref=footer"
                        >
                          Kyoto Bike Tour
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <b>Osaka</b>
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/product/a9a2ebd9-ced6-4793-a0cf-14488d92b03a?ref=footer"
                        >
                          Osaka Bar Hopping
                        </a>
                      </li>

                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/product/763e12a9-ef93-4ae4-8e17-cf86eda92b3f?ref=footer"
                        >
                          Osaka Foodie Tour
                        </a>
                      </li>

                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/product/7ed36da2-ba55-4267-8e8b-7f885ee6d901?ref=footer"
                        >
                          Kuromon Market Tour
                        </a>
                      </li>

                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/product/fcd0a539-dc55-4c44-8689-94e577d4bfcd?ref=footer"
                        >
                          Osaka Bike Tour
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className={styles.AppFooterCol}>
                <h6 className={styles.FooterHeading}>Contact Us</h6>
                <ul>
                  <li className={styles.FooterIconLink}>
                    <Link href="/faq">
                      <span className={styles.FooterIconLinkIcon}>
                        <QuestionsIcon color={"#fff"} size={24} />
                      </span>
                      <span className="UIT UITW1 UITS0">Support</span>
                    </Link>
                  </li>

                  <li
                    style={{
                      position: "relative",
                    }}
                  >
                    <Link
                      href="/business-partners"
                      className={styles["AppFooterTextLink FooterIconLink"]}
                    >
                      <span className={styles.FooterIconLinkIcon}>
                        <BusinessIcon size={24} />
                      </span>
                      <span className="UIT UITW1 UITS0">
                        Business/Promotional Inquiries
                      </span>
                    </Link>
                  </li>
                  <li className={styles.ContactAddress}>
                    <p>MagicalTrip Inc.</p>
                    <p style={{ marginTop: 0 }}>
                      Tokyotatemono Yaesunakdori Bldg. 12F, 1-4-10 Yaesu Chuo,
                    </p>
                    <p style={{ marginTop: 0 }}>Tokyo 103-0028 Japan</p>
                    <p>
                      <a
                        href="https://goo.gl/maps/gQ7oJfkDn4hBcu6Q7"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <b>Branch in Shinjuku Tokyo</b>
                        <br />
                        Tokyo, Shinjuku City, Nishishinjuku, 7 Chome-2-5
                      </a>
                    </p>
                    <p>
                      Certified Travel License: <br />
                      Tokyo Metropolitan Government Office: No.2-7941 <br />
                      <a
                        href="/Standard_General_Conditions_of_Travel_Agency_Business_For_MT_Web_Site.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginTop: 5, display: "block" }}
                      >
                        Sign (Registration Form) & Standard General Conditions{" "}
                        <br />
                        of Travel Agency Business
                      </a>
                      <br />
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className={styles.BottomFooter}>
            <div className="AppLayout">
              <div
                className={styles.AppFooterInner}
                style={{
                  display: "flex",
                  padding: 0,
                }}
              >
                <div className={styles.AppFooterCopyright}>
                  <small>
                    © {new Date().getFullYear()} MagicalTrip™ /
                    マジカルトリップ™
                  </small>
                </div>

                <ul className={styles.AppFooterNav}>
                  <li className={styles.AppFooterTap}>
                    <Link
                      href="/about-us"
                      style={{
                        fontWeight: "bold",
                        color: "var(--text-color-link)",
                        borderBottom: "3px solid transparent",
                      }}
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <a
                      className={styles.AppFooterTap}
                      href="https://media.magical-trip.com/?utm_source=magicaltrip&utm_medium=main"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Our Blog
                    </a>
                  </li>
                  <li className={styles.AppFooterTap}>
                    <Link href="/terms-of-service">Terms of Service</Link>
                  </li>
                  <li className={styles.AppFooterTap}>
                    <Link href="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li className={styles.AppFooterTap}>
                    <Link href="/external-transmission-rule">
                      External Transmission Rule
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/recruit?ref=footer"
                      className={styles.AppFooterTap}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Become a MagicalTrip Guide
                    </Link>
                  </li>
                  <li className={styles.AppFooterTap}>
                    <Link href="/faq">Support</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
}
