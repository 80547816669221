import { Dispatch, SetStateAction } from "react";
import SearchCard, { SearchCardProps } from "./SearchCard";
import ScalingDateRangePicker, {
  ScalingDateRangePickerProps,
} from "../../ScalingDateRangePicker";
import { formatDateRange } from "./DateRangeSearch";

type DateRangeSearchCardProps = Omit<SearchCardProps, "label"> & {
  from: Date | null;
  setFrom: Dispatch<SetStateAction<Date | null>>;
  until: Date | null;
  setUntil: Dispatch<SetStateAction<Date | null>>;
} & Pick<ScalingDateRangePickerProps, "maxRange">;

export default function DateRangeSearchCard({
  from,
  setFrom,
  until,
  setUntil,
  maxRange,
  ...searchCardProps
}: DateRangeSearchCardProps) {
  return (
    <SearchCard {...searchCardProps} label={formatDateRange(from, until)}>
      <ScalingDateRangePicker
        numCalenders={{
          max: 2,
        }}
        from={from}
        setFrom={setFrom}
        until={until}
        setUntil={setUntil}
        maxRange={maxRange}
      />
    </SearchCard>
  );
}
