import { format } from "date-fns";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
import useWindowSize from "../../../utils/useWindowSize";
import { useSearchContext } from "./SearchProvider";
import NormalSearchBar from "./variants/NormalSearchBar";
import SmallSearchBar from "./variants/SmallSearchBar";
import TinySearchBar from "./variants/TinySearchBar";

type SearchBarProps = {
  variant?: "tiny" | "small" | "normal" | "responsive";
  locationId?: string;
  from?: Date;
  until?: Date;
  groupSize?: number;
  reference?: string;
};

export default function SearchBar({
  variant = "responsive",
  reference,
}: SearchBarProps) {
  const router = useRouter();

  const [loading, setLoading] = useState(false);

  const { width } = useWindowSize();

  const searchContext = useSearchContext();

  const performSearch = async () => {
    setLoading(true);
    await router.push({
      pathname: "/search",
      query: {
        locationId: searchContext.locationId,
        from: formatDate(searchContext.from),
        until: formatDate(searchContext.until ?? searchContext.from),
        groupSize: searchContext.numGuests,
        tagIds: JSON.stringify(searchContext.tagArray),
        ...(reference && { ref: reference }),
      },
    });
    setLoading(false);
  };

  const searchBarProps = { ...searchContext, loading, performSearch };

  const calculatedVariant = useMemo(
    () =>
      (variant === "responsive"
        ? width <= 320
          ? "tiny"
          : width <= 640
          ? "small"
          : "normal"
        : variant) ?? "normal",
    [variant, width]
  );

  return {
    tiny: TinySearchBar,
    small: SmallSearchBar,
    normal: NormalSearchBar,
  }[calculatedVariant](searchBarProps);
}

const formatDate = (date: Date) => format(date, "yyyy-MM-dd");
