export const currencies = [
  "AUD",
  "BRL",
  "CAD",
  "CNY",
  "EUR",
  "GBP",
  "HKD",
  "ILS",
  "INR",
  "JPY",
  "KRW",
  "MYR",
  "NZD",
  "PHP",
  "SEK",
  "SGD",
  "THB",
  "TWD",
  "USD",
];
