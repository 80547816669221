import React, { Dispatch, SetStateAction, useMemo } from "react";
import DestinationPicker from "../../../../../Layout/Header/DestinationPicker";
import { formatLocation } from "../../../../MultiCardModal/components/LocationSearch";
import SearchCard, { OpenDialogProps } from "./SearchCard";

type LocationSearchProps = OpenDialogProps & {
  id: string;
  setId: Dispatch<SetStateAction<string>>;
};
export default function LocationSearch({
  id,
  setId,
  ...props
}: LocationSearchProps) {
  const value = useMemo(() => formatLocation(id), [id]);

  return (
    <SearchCard label="Where" value={value} {...props}>
      <DestinationPicker
        selectedId={id}
        onCityPick={(location) => {
          setId(location.id);
          props.openNext?.();
        }}
      />
    </SearchCard>
  );
}
