import { CSSProperties } from "react";
import styles from "./Loader.module.css";

function hex2rgb(hex: string) {
  hex = hex.replace(/^#/, "");

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  const num = parseInt(hex, 16);
  return [num >> 16, (num >> 8) & 255, num & 255];
}

interface Props {
  color?: string;
  size?: number;
  thickness?: number;
  style?: CSSProperties;
}

export default function Loader({
  color = "#000",
  size = 48,
  thickness = 6,
  style,
}: Props) {
  const [r, g, b] = hex2rgb(color);

  return (
    <span
      className={styles.Loader}
      style={{
        width: size,
        height: size,
        borderWidth: thickness,
        borderColor: `rgba(${r}, ${g}, ${b}, 0.2)`,
        borderLeftColor: color,
        ...style,
      }}
    />
  );
}
