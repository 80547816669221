import { CloudinaryImage } from "../../../Atoms/Cloudinary";
import data from "./data";
import styles from "./Partner.module.css";

interface Props {
  src: string;
  href: string;
  title: string;
}

function PartnerImage({ src, href, title }: Props) {
  const image = (
    <CloudinaryImage
      src={src}
      transformations={["w_112", "h_56"]}
      style={{ width: "100%", height: "auto" }}
    />
  );

  return href ? (
    <a
      href={href}
      title={title}
      rel="noopener"
      className={styles.FooterPartner}
    >
      {image}
    </a>
  ) : (
    <div className={styles.FooterPartner}>{image}</div>
  );
}

export default function PartnerList() {
  return (
    <div className={styles.FooterPartnerList}>
      {data.entries.map((entry, index) => {
        return (
          <PartnerImage
            key={index}
            title={entry.title}
            href={entry.href}
            src={entry.src}
          />
        );
      })}
    </div>
  );
}
