const baseUrl = `https://magical-trip.s3.ap-northeast-1.amazonaws.com/marketplace-storefront-webapp`;

const partners = {
  entries: [
    {
      title: "Timeout Tokyo",
      src: `${baseUrl}/logo-timeout-tokyo2.png`,
      href: "https://www.timeout.com/tokyo/things-to-do/five-reasons-why-you-should-book-a-local-guided-tour-with-magical-trip",
    },
    {
      title: "CNN Travel",
      href: "https://edition.cnn.com/travel/article/top-tours-japan/index.html",
      src: `${baseUrl}/cnn-grt.png`,
    },
    {
      title: "Forbes",
      href: "https://www.forbes.com/sites/davidhochman/2023/05/06/discover-the-best-of-kyotos-cuisine-with-magical-trips-nighttime-food-tour/?sh=38ea6e31502b",
      src: `${baseUrl}/Forbes-Emblem.png`,
    },
    {
      title: "South China Morning Post",
      href: "http://www.scmp.com/lifestyle/travel-leisure/article/2123160/discover-real-tokyo-after-dark-new-bar-hopping-tour-and",
      src: `${baseUrl}/logo-south-china-morning-post-2.png`,
    },
  ],
};

export default partners;
