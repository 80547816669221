export const paymentTypes = {
  ALIPAY: "Alipay",
  CARD: "Card",
  PAYPAL: "Paypal",
  UNIONPAY: "UnionPay",
};

export const countryCodes = {
  JP: "JP",
};

export const groupTypes = {
  COUPLE: "COUPLE",
  FAMILY: "FAMILY",
  FRIENDS: "FRIENDS",
  SOLO: "SOLO",
};

export const locationIds = {
  HIROSHIMA: "9ae6ec82-75b7-a1ae-9b48-a68b9e376e0f",
  KYOTO: "c0de54d6-b39c-fd15-a578-750e4bc26fc7",
  OSAKA: "5ea1a33e-a082-eef5-bc24-7dad4e310488",
  TOKYO: "96d199a0-87fe-3e1e-3cf3-20f4b27d5fef",
  NARA: "a837fec7-6f30-4e51-c047-708abe7b690b",
  HOKKAIDO: "b2d7e01f-70e2-ed86-fe7b-5cb51e777fc7",
  HYOGO: "a7367b88-3881-ca60-c3df-4fb013320cb8",
  KENROKUEN: "77e8df68-ea76-743a-49a5-c69497aed200",
  THAILAND: "3d7f39d1-e25a-40be-914c-620e61708126",
  YOKOHAMA: "67ca14a9-aaea-4254-9ab8-c992802110a4",
  OKINAWA: "f4de2e31-f967-406f-bed1-94a8afff18cf",
  ISHIKAWA: "bc46f25b-0912-4f2a-819f-eb703d46c334",
};

export const tourSortingTypes = {
  AVAILABILITY: "AVAILABILITY",
  POPULARITY: "POPULARITY",
  CREATION_RECENCY: "CREATION_RECENCY",
};

export const trafficSources = {
  GOOGLE_CPC: "GOOGLE_CPC",
};
