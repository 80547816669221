import classNames from "classnames";
import {
  DetailedHTMLProps,
  HTMLAttributes,
  MutableRefObject,
  PropsWithChildren,
} from "react";
import s from "../SearchBar.module.scss";

type SearchBarElementProps = {
  selected?: boolean;
  label: string;
  value: string;
  variant?: "default" | "mobile";
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  buttonRef?: MutableRefObject<any>;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export default function SearchBarElement({
  variant = "default",
  selected,
  label,
  value,
  startAdornment,
  endAdornment,
  children,
  buttonRef,
  ...props
}: PropsWithChildren<SearchBarElementProps>) {
  return (
    <div className={s.SearchBarElementContainer}>
      <div
        className={classNames(s.SearchBarElement, {
          [s.Mobile]: variant === "mobile",
          [s.Selected]: selected,
        })}
        ref={buttonRef}
        {...props}
      >
        {startAdornment && startAdornment}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            width: "90%",
          }}
        >
          <label className={classNames({ [s.Large]: variant === "mobile" })}>
            {label}
          </label>
          <span
            className={classNames({ [s.Large]: variant !== "mobile" })}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </span>
        </div>
        {endAdornment && endAdornment}
      </div>
      {selected && <dialog className={s.SearchBarDialog}>{children}</dialog>}
    </div>
  );
}
