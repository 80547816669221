import Button from "../../../Atoms/Button";
import { TagSelectorProps } from "./TagSearchCard";

export function TagSelector({ tags, setTags, tagOptions }: TagSelectorProps) {
  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "0.5rem" }}>
      {Array.from(tagOptions).map(([id, name]) => {
        const tagIsSelected = tags.has(id);

        return (
          <Button
            key={id}
            phantom={!tagIsSelected}
            style={{ width: "auto" }}
            onClick={() =>
              setTags((prevTags) => {
                const tags = new Set([...prevTags]);
                tagIsSelected ? tags.delete(id) : tags.add(id);
                return tags;
              })
            }
          >
            {name}
          </Button>
        );
      })}
    </div>
  );
}

export const formatTags = ({
  tags,
  tagOptions,
}: Omit<TagSelectorProps, "setTags">) => {
  if (tags.size === 0) return "Anything";

  return Array.from(tags)
    .map((id) => tagOptions.get(id))
    .join(", ");
};
