import { Dispatch, SetStateAction, useMemo } from "react";
import { Tag } from "../../../../graphql/operations";
import SearchCard, { SearchCardProps } from "./SearchCard";
import { TagSelector, formatTags } from "./TagSearch";

export type TagSelectorProps = {
  tags: Set<Tag["id"]>;
  setTags: Dispatch<SetStateAction<Set<Tag["id"]>>>;
  tagOptions: Map<Tag["id"], Tag["name"]>;
};

export type TagSearchCardProps = Omit<SearchCardProps, "label"> &
  TagSelectorProps;

export default function TagSearchCard({
  tags,
  setTags,
  tagOptions,
  ...searchCardProps
}: TagSearchCardProps) {
  const label = useMemo(
    () => formatTags({ tags, tagOptions }),
    [tags, tagOptions]
  );

  return (
    <SearchCard {...searchCardProps} label={label}>
      <TagSelector tags={tags} setTags={setTags} tagOptions={tagOptions} />
    </SearchCard>
  );
}
