import { Dispatch, SetStateAction } from "react";
import s from "./GuestSearch.module.scss";

export const formatGuests = (numGuests: number) =>
  `${numGuests} guest${numGuests !== 1 ? "s" : ""}`;

export const defaultMaxGuests = 99 as const;

export type GuestSearchProps = {
  numGuests: number;
  setNumGuests: Dispatch<SetStateAction<number>>;
  maxGuests?: number;
};

export function GuestSearch({
  numGuests,
  setNumGuests,
  maxGuests = defaultMaxGuests,
}: GuestSearchProps) {
  const minGuests = 1;

  return (
    <div className={s.Container}>
      <div className={s.Category} style={{ flexGrow: 1 }}>
        Guests
      </div>
      <div className={s.ButtonGroup}>
        <button
          className={s.IncrementButton}
          disabled={numGuests === minGuests}
          onClick={() =>
            setNumGuests((numGuests) => Math.max(minGuests, numGuests - 1))
          }
        >
          -
        </button>
        <div>{numGuests}</div>
        <button
          className={s.IncrementButton}
          disabled={numGuests === maxGuests}
          onClick={() =>
            setNumGuests((numGuests) => Math.min(maxGuests, numGuests + 1))
          }
        >
          +
        </button>
      </div>
    </div>
  );
}
