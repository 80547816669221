import { useMemo } from "react";
import {
  TagSelector,
  formatTags,
} from "../../../../MultiCardModal/components/TagSearch";
import { TagSelectorProps } from "../../../../MultiCardModal/components/TagSearchCard";
import SearchCard, { OpenDialogProps } from "./SearchCard";

type TagSearchProps = OpenDialogProps & TagSelectorProps;

export default function TagSearch({
  tags,
  setTags,
  tagOptions,
  ...props
}: TagSearchProps) {
  const value = useMemo(
    () => formatTags({ tags, tagOptions }),
    [tags, tagOptions]
  );

  return (
    <SearchCard label="What" value={value} {...props}>
      <div
        style={{
          width: "420px",
          padding: "24px 32px 24px 32px",
        }}
      >
        <TagSelector tags={tags} setTags={setTags} tagOptions={tagOptions} />
      </div>
    </SearchCard>
  );
}
