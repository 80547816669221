import styles from "./SelectButton.module.css";

type SelectButtonProps = {
  pressed?: boolean;
  onClick: (e: any) => void;
};

export default function SelectButton({
  children,
  pressed = false,
  onClick,
}: React.PropsWithChildren<SelectButtonProps>) {
  return (
    <button
      aria-pressed={pressed}
      type="button"
      onClick={onClick}
      className={styles.SelectButton}
    >
      {children}
    </button>
  );
}
