import { ApolloProvider } from "@apollo/client";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { GoogleTagManager } from "@next/third-parties/google";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { nanoid } from "nanoid";
import type { AppProps } from "next/app";
import Router from "next/router";
import NProgress from "nprogress";
import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { AnalyticsProvider } from "../components/Atoms/Analytics";
import { CloudinaryProvider } from "../components/Atoms/Cloudinary";
import { SearchProvider } from "../components/Cards/SearchBar/SearchProvider";
import Layout from "../components/Layout";
import { useApollo } from "../graphql/apolloClient";
import "../styles/globals.scss";
import "../styles/nprogress.css";
import * as analytics2 from "../utils/analytics2";

const isDev = () => process.env.ENVIRONMENT !== "production";

const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: process.env.GROWTHBOOK_ID,
  enableDevMode: isDev(),
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {
    if ("gtag" in window && process.env.ENVIRONMENT === "production") {
      window.gtag("event", "experiment_viewed", {
        event_category: "experiment",
        experiment_id: experiment.key,
        variation_id: result.variationId,
      });
    } else {
      console.log("no gtag");
    }
  },
});

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

const analyticsTracker = isDev()
  ? analytics2.defaultTracker
  : analytics2.tracker;

function MyApp({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps);
  const stripe = loadStripe(process.env.STRIPE_PUBLIC_KEY);
  const cloudinaryAutoUploadMappings = [
    {
      folder: process.env.CLOUDINARY_AUTO_UPLOAD_MAPPING_FOLDER as string,
      urlPrefix: process.env.CLOUDINARY_S3_MAPPING_URL_PREFIX as string,
    },
  ];

  useEffect(() => {
    // Load features asynchronously when the app renders
    if (growthbook) {
      growthbook.init({ streaming: true });

      let visitor_id = localStorage.getItem("visitor_id");
      if (!visitor_id) {
        visitor_id = nanoid();
        localStorage.setItem("visitor_id", visitor_id);
      }
      growthbook.setAttributes({ id: visitor_id });
    }
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <CloudinaryProvider
        cloudName={process.env.CLOUDINARY_CLOUD_NAME}
        autoUploadMappingFolder={cloudinaryAutoUploadMappings as any}
      >
        <Elements stripe={stripe}>
          <GoogleTagManager gtmId={`GTM-${process.env.GTM_ID}`} />
          <AnalyticsProvider tracker={analyticsTracker}>
            <GrowthBookProvider growthbook={growthbook}>
              <SearchProvider>
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              </SearchProvider>
            </GrowthBookProvider>
          </AnalyticsProvider>
        </Elements>
      </CloudinaryProvider>
    </ApolloProvider>
  );
}

export default MyApp;
