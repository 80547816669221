import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import { Currency, Iso639 } from "../../../graphql/operations";
const Cookies = require("js-cookie");

interface Session {
  countryCode: string;
  currencyCode: Currency;
  languageCode: Iso639;
  source?: string;
}

interface SessionContextT {
  session: Session;
}

const SessionContext = createContext<SessionContextT>({
  session: {
    countryCode: Intl.DateTimeFormat().resolvedOptions().timeZone || "",
    currencyCode: Cookies.get("currency") || "USD",
    languageCode: Iso639.En,
  },
});

interface Props {
  session: Session;
  children: ReactNode;
}

export function SessionProvider({ session, children }: Props) {
  return (
    <SessionContext.Provider value={{ session }}>
      {children}
    </SessionContext.Provider>
  );
}

export function useSession() {
  const { session } = useContext(SessionContext);

  return session;
}
