import { addDays, getDate, getMonth, isEqual, startOfDay } from "date-fns";

export const formatMonth = (date: Date) =>
  date.toLocaleDateString("en-US", { month: "short" });

export const formatDateRange = (from: Date, until: Date) => {
  return `${formatMonth(from)} ${getDate(from)}${
    !isEqual(from, until) && until
      ? ` - ${
          !(getMonth(from) === getMonth(until)) ? formatMonth(until) : ""
        } ${getDate(until)}`
      : ""
  }`;
};

export const defaultDateRange = () => {
  const today = startOfDay(new Date());
  return { from: today, until: addDays(today, 3) };
};
