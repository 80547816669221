import { TouchEvent, useState } from "react";

interface SwipeInput {
  onSwipedLeft: () => void;
  onSwipedRight: () => void;
  minSwipeDistance?: number;
}

interface SwipeOutput {
  onTouchStart: (e: TouchEvent) => void;
  onTouchMove: (e: TouchEvent) => void;
  onTouchEnd: () => void;
}

type TouchPosition = {
  clientX: number;
  clientY: number;
};
export default function useSwipe({
  onSwipedLeft,
  onSwipedRight,
  minSwipeDistance = 50,
}: SwipeInput) {
  const [touchStart, setTouchStart] = useState<TouchPosition | null>(null);
  const [touchEnd, setTouchEnd] = useState<TouchPosition | null>(null);

  const onTouchStart = (e: TouchEvent) => {
    setTouchEnd(null);
    const { clientX, clientY } = e.targetTouches[0];
    setTouchStart({ clientX, clientY });
  };

  const onTouchMove = (e: TouchEvent) => {
    const { clientX, clientY } = e.targetTouches[0];

    setTouchEnd({ clientX, clientY });
  };
  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const xDistance = touchStart.clientX - touchEnd.clientX;
    const yDistance = touchStart.clientY - touchEnd.clientY;
    if (Math.abs(yDistance) > Math.abs(xDistance)) return;

    const isLeftSwipe = xDistance > minSwipeDistance;
    const isRightSwipe = xDistance < -minSwipeDistance;
    if (isLeftSwipe) {
      onSwipedLeft();
    }
    if (isRightSwipe) {
      onSwipedRight();
    }
  };

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
}
