import { useQuery } from "@apollo/client";
import { addDays, startOfDay } from "date-fns";
import React, { createContext, useContext, useMemo, useState } from "react";
import { locationIds } from "../../../core/enums";
import {
  GetTagOptionsDocument,
  GetTagOptionsQuery,
  Tag,
} from "../../../graphql/operations";

const useSearchProviderValues = () => {
  const [locationId, setLocationId] = useState<string>(locationIds.TOKYO);
  const [from, setFrom] = useState<Date>(startOfDay(new Date()));
  const [until, setUntil] = useState<Date>(startOfDay(addDays(new Date(), 3)));
  const [numGuests, setNumGuests] = useState<number>(2);
  const [tags, setTags] = useState<Set<Tag["id"]>>(new Set());
  const tagArray = useMemo(() => Array.from(tags), [tags]);

  const { data: tagOptionData } = useQuery<GetTagOptionsQuery>(
    GetTagOptionsDocument
  );

  const tagOptions = useMemo(
    () =>
      new Map(
        (tagOptionData?.tags.records ?? []).map(({ id, name }) => [id, name])
      ),
    [tagOptionData]
  );

  return {
    locationId,
    from,
    until,
    numGuests,
    tags,
    tagArray,
    tagOptions,
    setLocationId,
    setFrom,
    setUntil,
    setNumGuests,
    setTags,
  };
};

const SearchContext = createContext<ReturnType<
  typeof useSearchProviderValues
> | null>(null);

const SearchProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <SearchContext.Provider value={useSearchProviderValues()}>
      {children}
    </SearchContext.Provider>
  );
};

const useSearchContext = () => {
  const context = useContext(SearchContext);
  if (!context)
    throw new Error("useSearchContext must be used within a SearchProvider");
  return context;
};

export { SearchProvider, useSearchContext };
