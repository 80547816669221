import { useState } from "react";
import { GrSearch } from "react-icons/gr";
import Loader from "../../../Atoms/Loader";
import s from "../SearchBar.module.scss";
import { SearchBarProps } from "../types";
import SearchModal from "./SearchModal";

export default function TinySearchBar({ loading, ...props }: SearchBarProps) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {loading ? (
        <div
          style={{
            transform: "translateX(4px)",
          }}
        >
          <Loader size={32} thickness={4} color="#fff" />
        </div>
      ) : (
        <button
          style={{
            backgroundColor: "transparent",
            backgroundRepeat: "no-repeat",
            border: "none",
            cursor: "pointer",
            overflow: "hidden",
            outline: "none",
          }}
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <GrSearch
            className={s.SmallIcon}
            size={20}
            style={{
              transform: "translateY(2px)",
              stroke: "white",
              fill: "white",
            }}
          />
        </button>
      )}
      <SearchModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        {...props}
      />
    </>
  );
}
