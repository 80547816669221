import type { ReactNode } from "react";
import {
  Children,
  cloneElement,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";
import type { Tracker } from "../../../utils/analytics2";
import { defaultTracker } from "../../../utils/analytics2";

interface Context {
  tracker: Tracker;
}

const AnalyticsContext = createContext<Context>({
  tracker: defaultTracker,
});

interface Props {
  children: ReactNode;
  tracker: Tracker;
}

export function AnalyticsProvider({ tracker, children }: Props) {
  return (
    <AnalyticsContext.Provider value={{ tracker }}>
      {children}
    </AnalyticsContext.Provider>
  );
}

interface TrackingEvent {
  category: string;
  action: string;
  label?: string;
  value?: string;
}

type EventTrackerProps = {
  onClick?: TrackingEvent;
  onBlur?: TrackingEvent;
  onChange?: TrackingEvent;
};

const allowedEventNames = ["onClick", "onBlur", "onChange"];

function isAllowedEvent(eventPropName: string) {
  return allowedEventNames.includes(eventPropName);
}

export function EventTracker({
  children,
  ...props
}: React.PropsWithChildren<EventTrackerProps>) {
  const context = useContext(AnalyticsContext);

  const element: any = useMemo(() => Children.only(children), [children]);

  const handleEventType = useCallback(
    (prop: string, trackingEvent: any, e: Event) => {
      const handleEvent = element.props[prop];

      if (typeof handleEvent === "function") {
        handleEvent(e);
      }

      context.tracker.trackEvent(trackingEvent);
    },
    [context.tracker, element.props]
  );

  const newProps = useMemo(
    () =>
      Object.entries(props).reduce(
        (acc, [eventPropName, trackingEvent]: [string, any]) => {
          if (!isAllowedEvent(eventPropName)) {
            return acc;
          }

          return {
            ...acc,
            [eventPropName]: (e: Event) =>
              handleEventType(eventPropName, trackingEvent, e),
          };
        },
        {}
      ),
    [handleEventType, props]
  );

  const clonedElement = useMemo(
    () => cloneElement(element, newProps),
    [element, newProps]
  );

  return clonedElement;
}

export function useAnalyticsTracker() {
  const { tracker } = useContext(AnalyticsContext);

  return tracker;
}
