import { gql, useQuery } from "@apollo/client";
import classNames from "classnames";
import { locationIds } from "../../../core/enums";
import { Location, LocationTranslation } from "../../../graphql/operations";
import Loader from "../../Atoms/Loader";
import { Text } from "../../Atoms/Typography";
import styles from "./Header.module.css";

const keywordMap: { [key: string]: string } = {
  [locationIds.TOKYO]: "Shinjuku, Shibuya, Asakusa",
  [locationIds.OSAKA]: "Dotonbori, Namba, Kuromon",
  [locationIds.KYOTO]: "Fushimi Inari, Arashiyama, Pontocho",
  [locationIds.HIROSHIMA]: "Heiwa, Miyajima",
  [locationIds.NARA]: "Deer park, Tōdaiji-ji",
  [locationIds.HOKKAIDO]: "Hokkaido, Susukino",
  [locationIds.HYOGO]: "Himeji castle, Hyogo",
  [locationIds.KENROKUEN]: "Kenrokuen Garden, Samurai District",
  [locationIds.THAILAND]: "Thai Food, Chinatown, Tuk Tuk ride",
  [locationIds.YOKOHAMA]: "Yokohama, Kamakura, Odawara",
};

interface Props {
  onCityPick: (location: Location) => void;
  selectedId?: string;
}

export default function DestinationPicker({ onCityPick, selectedId }: Props) {
  const { loading, error, data } =
    useQuery<GetLocationsResponse>(GET_LOCATIONS);

  if (loading) {
    return <Loader />;
  }

  if (error || !data || !data.locations) {
    return <>Could not load cities.</>;
  }

  return (
    <ul className={styles.DestinationPicker}>
      {(data.locations.records || []).map(
        (location) =>
          location && (
            <li
              key={location.id}
              onClick={() => onCityPick(location as Location)}
            >
              <div
                className={classNames(styles.DestinationPickerItem, {
                  [styles.Selected]: location.id === selectedId,
                })}
              >
                <b>{location.translation?.name}</b>
                <Text size="smaller" muted>
                  {keywordMap[location.id]}
                </Text>
              </div>
            </li>
          )
      )}
    </ul>
  );
}

type GetLocationsResponse = {
  locations: {
    records: (Pick<Location, "id"> & {
      translation: Pick<LocationTranslation, "name">;
    })[];
  };
};

const GET_LOCATIONS = gql`
  query DestinationPickerQuery {
    locations(
      input: {
        hasProducts: true
        locationTypes: [PREFECTURE, CITY]
        order: { field: "NUM_PRODUCTS", direction: DESC }
      }
    ) {
      records {
        id
        translation(lang: en) {
          name
        }
      }
    }
  }
`;
