import styles from "./CheckoutHeader.module.scss";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import CurrencyPicker from "../../Atoms/CurrencyPicker";

export default function CheckoutHeader() {
  const router = useRouter();
  const [showCurrency, setShowCurrency] = useState(true);
  useEffect(() => {
    if (router.pathname === "/bookings/[id]") {
      setShowCurrency(false);
    } else {
      setShowCurrency(true);
    }
  }, [router]);

  const isStep2 =
    router.pathname === "/bookings/[id]" ||
    router.pathname === "/payments/[id]/checkout-confirmation";

  const isStep3 = router.pathname === "/payments/[id]/checkout-confirmation";

  return (
    <div
      style={{
        boxShadow: "0px 0px 4px 0px #0003",
        position: "relative",
        zIndex: 2,
      }}
    >
      <div className="AppLayout">
        <div className={styles["CheckoutHeader"]}>
          <div
            className={styles["HeaderBackBtn"]}
            onClick={() => window.history.back()}
          >
            <svg
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.6551 5.934H3.50006L7.91089 1.52317L6.73256 0.344833L0.310059 6.76733L6.73256 13.1898L7.91089 12.0115L3.50006 7.60067H15.6551V5.934Z"
                fill="#282828"
              />
            </svg>
            <strong>Back</strong>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ol className={styles["stepper"]}>
              <li className={styles["active"]}>
                <div
                  className={
                    isStep2 || isStep3
                      ? `${styles.bar} ${styles.active}`
                      : styles.bar
                  }
                />
                <span>Contact</span>
              </li>
              <li className={isStep2 ? styles.active : null}>
                <div className={styles["bar"]} />
                <span>Payment</span>
              </li>
              <li className={isStep3 ? styles.active : null}>
                <span>Confirmation</span>
              </li>
            </ol>
            {showCurrency ? (
              <ul>
                <li className={styles.CurrencyDropDown}>
                  <CurrencyPicker />
                </li>
              </ul>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
