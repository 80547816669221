import { useState, useEffect } from "react";

const isMobile = (width: number) => width <= 640;

export default function useWindowSize() {
  const isSSR = typeof window === "undefined";
  const [windowSize, setWindowSize] = useState(() => {
    const width = isSSR ? 1200 : window.innerWidth;
    return {
      width,
      height: isSSR ? 800 : window.innerHeight,
      isMobile: isMobile(width),
    };
  });

  function changeWindowSize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
      isMobile: isMobile(window.innerWidth),
    });
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", changeWindowSize);

      return () => {
        window.removeEventListener("resize", changeWindowSize);
      };
    }
    return;
  }, []);

  return windowSize;
}
