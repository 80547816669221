import { useState, useEffect } from "react";
import { FaAngleDown as Icon } from "react-icons/fa";
import { useSession } from "../AppSession";
import { currencies } from "../../../constants/currencies";
import styles from "./CurrencyPicker.module.css";
import { useRouter } from "next/router";
import qs from "query-string";
const Cookies = require("js-cookie");

interface State {
  redirectTo: string | null;
}

export default function CurrencyPicker() {
  const session = useSession();
  const [state, setState] = useState<State>({
    redirectTo: null,
  });
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const router = useRouter();

  useEffect(() => {
    setSelectedCurrency(session.currencyCode);
    if (state.redirectTo) {
      window.location.href = state.redirectTo;
    }
  }, [state.redirectTo, session.currencyCode]);

  function handleCurrencyChange(currencyCode: string) {
    const query = router.query;
    const queryString = qs.stringify(query);
    const newLocation = `${window.location.origin}${window.location.pathname}?${queryString}`;
    Cookies.set("currency", currencyCode);
    setState({
      redirectTo: newLocation,
    });
  }

  return (
    <div className={styles.CurrencyPicker}>
      <label htmlFor="header-currency-picker" className="visually-hidden">
        change currency
      </label>
      <select
        id="header-currency-picker"
        value={selectedCurrency}
        onChange={(e) => handleCurrencyChange(e.target.value)}
      >
        {currencies.map((currency) => {
          return (
            <option key={currency} value={currency}>
              {currency}
            </option>
          );
        })}
      </select>
      <span className={styles.CurrencyPickerIcon}>
        <Icon color="#fff" />
      </span>
    </div>
  );
}
