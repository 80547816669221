import { ApolloLink } from "@apollo/client";
import qs from "query-string";
import { Tracker } from "./analytics2";

interface ShareASaleEvent {
  amount: number;
  currency: string;
  tracking: string;
}

const SHAREASALE_MERCHANT_ID = 80059;

function trackShareSale({ amount, currency, tracking }: ShareASaleEvent) {
  const queryString = qs.stringify({
    amount,
    currency,
    tracking,
    transtype: "sale",
    merchantID: SHAREASALE_MERCHANT_ID,
  });

  const src = `https://shareasale.com/sale.cfm?${queryString}`;

  if (process.env.ENVIRONMENT === "production") {
    const image = document.createElement("img");
    image.width = 1;
    image.height = 1;
    image.src = src;
  } else {
    console.warn("[dev-mode] track-share-sale", src); // eslint-disable-line
  }
}

interface PurchaseData {
  productId: string;
  productName: string;
  orderId: string;
  amountInMajorUnits: number;
  currencyCode: string;
}

export function trackTransaction({
  productId,
  productName,
  orderId,
  amountInMajorUnits,
  currencyCode,
}: PurchaseData) {
  trackShareSale({
    amount: amountInMajorUnits,
    currency: currencyCode,
    tracking: orderId,
  });

  if (window && typeof window.gtag === "function") {
    window.gtag("event", "purchase", {
      transaction_id: orderId,
      value: amountInMajorUnits,
      currency: currencyCode,
      items: [
        {
          item_id: productId,
          item_name: productName,
          item_brand: "MagicalTrip",
          price: amountInMajorUnits,
          quantity: 1,
        },
      ],
    });
    window.gtag("event", "conversion", {
      send_to: "AW-877375277/25O3CJ2t4PIYEK3erqID",
      value: amountInMajorUnits,
      currency: currencyCode,
    });
  }

  if (window && typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      value: amountInMajorUnits,
      currency: currencyCode,
    });
  }
}

export const createApolloAnalyticsLink = (tracker: Tracker) =>
  new ApolloLink((operation, forward) => {
    if (!forward) {
      return null;
    }

    return forward(operation).map((result) => {
      try {
        switch (operation.operationName) {
          case "ProcessPayment2Mutation": {
            const {
              processPayment: { order, payment },
            }: any = result.data;

            const purchase = {
              productId: order.product.id,
              productName: order.product.translation.name,
              amountInMajorUnits: payment.price.amountInMajorUnits,
              currencyCode: payment.price.currencyCode,
              orderId: order.id,
            };

            trackTransaction(purchase);

            break;
          }
        }
      } catch (err) {
        console.warn("Analytics Link: Could not send event.", err.message); // eslint-disable-line
      }

      return result;
    });
  });
