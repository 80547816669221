import { GuestSearch, GuestSearchProps, formatGuests } from "./GuestSearch";
import SearchCard, { SearchCardProps } from "./SearchCard";

type GuestSearchCardProps = Omit<SearchCardProps, "label"> & GuestSearchProps;

export default function GuestSearchCard({
  numGuests,
  setNumGuests,
  maxGuests,
  ...searchCardProps
}: GuestSearchCardProps) {
  return (
    <SearchCard {...searchCardProps} label={formatGuests(numGuests)}>
      <GuestSearch
        numGuests={numGuests}
        setNumGuests={setNumGuests}
        maxGuests={maxGuests}
      />
    </SearchCard>
  );
}
