import { CloudinaryImage } from "../../Atoms/Cloudinary";

interface Props {
  name: string;
  width: number;
}

const ASPECT_RATIO = 1.6;

export default function CityPoster({ name, width }: Props) {
  const src = buildSrc(name);
  const height = Math.round(width * ASPECT_RATIO);

  return (
    <CloudinaryImage
      src={src}
      transformations={[`w_${width}`, `h_${height}`]}
    />
  );
}

const buildSrc = (name: string) =>
  `https://magical-trip.s3.ap-northeast-1.amazonaws.com/posters/cityart-${name}-5.jpg`;
