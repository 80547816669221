import type { ImgHTMLAttributes } from "react";
import { useContext } from "react";
import { CloudinaryContext } from "./Provider";
import { CloudinaryTransformationParamKey } from "./constants";
import Image from "next/image";

const cloudinaryImageDefaultTransformations = ["q_auto", "f_auto", "dpr_2.0"];

interface CloudinaryImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  transformations?: Array<CloudinaryTransformationParamKey>;
  objPosition?: string;
  objFit?: any;
  isPriority?: boolean;
  layout?: React.ComponentProps<typeof Image>["layout"];
  width?: React.ComponentProps<typeof Image>["width"];
  height?: React.ComponentProps<typeof Image>["height"];
}

export function CloudinaryImageUrl({
  src,
  transformations,
}: CloudinaryImageProps) {
  const { cloudName, autoUploadMappingFolder } = useContext(CloudinaryContext);
  const stringifiedParams = cloudinaryImageDefaultTransformations
    .concat(transformations || [])
    .join(",");

  const cloudinaryStagingRoot = `https://res.cloudinary.com/${cloudName}/image/upload/${stringifiedParams}/v1/${autoUploadMappingFolder[0]?.folder}/`;
  const cloudinaryProductionRoot = `https://res.cloudinary.com/${cloudName}/image/upload/${stringifiedParams}/v1/${autoUploadMappingFolder[0]?.folder}/`;

  const url = src
    ?.replace(`${autoUploadMappingFolder[0]?.urlPrefix}`, cloudinaryStagingRoot)
    ?.replace(
      `${autoUploadMappingFolder[0]?.urlPrefix}`,
      cloudinaryProductionRoot
    );

  if (!url || !url.includes("cloudinary")) {
    const appendRoot =
      `https://res.cloudinary.com/${cloudName}/image/upload/${stringifiedParams}/v1/` +
      src;
    return appendRoot;
  }

  if (url && !url.includes(stringifiedParams) && !url.includes("/v1/tours/")) {
    return url.replace(
      `https://res.cloudinary.com/${cloudName}/image/upload/`,
      `https://res.cloudinary.com/${cloudName}/image/upload/${stringifiedParams}/`
    );
  }

  return url;
}
export function CloudinaryImage({
  src,
  alt,
  transformations,
  objPosition,
  isPriority,
  layout,
  width,
  height,
  objFit,
}: CloudinaryImageProps) {
  const imageSrc = CloudinaryImageUrl({ src, transformations });

  return (
    <Image
      unoptimized={true}
      placeholder="blur"
      blurDataURL="data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAIAAoDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAb/xAAhEAACAQMDBQAAAAAAAAAAAAABAgMABAUGIWEREiMxUf/EABUBAQEAAAAAAAAAAAAAAAAAAAMF/8QAGhEAAgIDAAAAAAAAAAAAAAAAAAECEgMRkf/aAAwDAQACEQMRAD8AltJagyeH0AthI5xdrLcNM91BF5pX2HaH9bcfaSXWGaRmknyJckliyjqTzSlT54b6bk+h0R//2Q=="
      src={imageSrc!}
      fill={layout ? false : true}
      priority={isPriority ? isPriority : false}
      alt={alt || "image"}
      width={width}
      height={height}
      style={{
        maxWidth: "100%",
        objectFit: objFit ? objFit : "cover",
        objectPosition: objPosition ? objPosition : "center",
      }}
    />
  );
}

export default CloudinaryImage;
