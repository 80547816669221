import React, { useMemo } from "react";
import SearchCard, { OpenDialogProps } from "./SearchCard";
import {
  GuestSearch as GuestSearchCard,
  GuestSearchProps,
  formatGuests,
} from "../../../../MultiCardModal/components/GuestSearch";

type GuestSearch = OpenDialogProps & GuestSearchProps;

export default function GuestSearch({
  numGuests,
  setNumGuests,
  ...props
}: GuestSearch) {
  const value = useMemo(() => formatGuests(numGuests), [numGuests]);

  return (
    <SearchCard label="How many" value={value} {...props}>
      <div
        style={{
          width: "320px",
          padding: "24px 32px 24px 32px",
        }}
      >
        <GuestSearchCard numGuests={numGuests} setNumGuests={setNumGuests} />
      </div>
    </SearchCard>
  );
}
