import type { ReactNode } from "react";
import { createContext } from "react";

interface CloudinaryProviderProps {
  cloudName: string;
  autoUploadMappingFolder: [
    {
      folder: string;
      urlPrefix: string;
    }
  ];

  children: ReactNode;
}

export const CloudinaryContext = createContext({
  cloudName: "",
  autoUploadMappingFolder: [{ folder: "", urlPrefix: "" }],
});

export function CloudinaryProvider({
  children,
  ...props
}: CloudinaryProviderProps) {
  return (
    <CloudinaryContext.Provider value={props}>
      {children}
    </CloudinaryContext.Provider>
  );
}

export default CloudinaryProvider;
