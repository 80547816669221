import { Dispatch, SetStateAction, useMemo } from "react";
import { formatDateRange } from "../../../../MultiCardModal/components/DateRangeSearch";
import ScalingDateRangePicker from "../../../../ScalingDateRangePicker";
import SearchCard, { OpenDialogProps } from "./SearchCard";

export type DateRange = {
  from: Date;
  until: Date;
};

type DateRangeSearchProps = OpenDialogProps & {
  from: Date | null;
  setFrom: Dispatch<SetStateAction<Date | null>>;
  until: Date | null;
  setUntil: Dispatch<SetStateAction<Date | null>>;
};

export default function DateRangeSearch({
  from,
  until,
  setFrom,
  setUntil,
  ...props
}: DateRangeSearchProps) {
  const formattedRange = useMemo(
    () => formatDateRange(from, until),
    [from, until]
  );

  return (
    <SearchCard label="When" value={formattedRange} {...props}>
      <div style={{ maxWidth: "100vw", padding: 48 }}>
        <ScalingDateRangePicker
          from={from}
          until={until}
          setFrom={setFrom}
          setUntil={setUntil}
          numCalenders={2}
        />
      </div>
    </SearchCard>
  );
}
