import { useState } from "react";
import s from "../../SearchBar.module.scss";
import SearchButton from "../../components/SearchButton";
import { SearchBarProps } from "../../types";
import DateRangeSearch from "./components/DateRangeSearch";
import GuestSearch from "./components/GuestSearch";
import LocationSearch from "./components/LocationSearch";
import { OpenDialogProps } from "./components/SearchCard";
import TagSearch from "./components/TagSearch";

export default function NormalSearchBar({
  locationId,
  setLocationId,
  from,
  setFrom,
  until,
  setUntil,
  numGuests,
  setNumGuests,
  tags,
  setTags,
  tagOptions,
  performSearch,
  loading,
}: SearchBarProps) {
  const [openDialogIndex, setOpenDialogIndex] = useState<number | null>(null);

  const getDialogProps = (index: number) =>
    ({
      isOpen: openDialogIndex === index,
      openDialog: () => setOpenDialogIndex(index),
      closeDialog: () => setOpenDialogIndex(null),
      openNext: () => setOpenDialogIndex((index + 1) % 3),
    } as OpenDialogProps);

  return (
    <div className={s.SearchBar}>
      <LocationSearch
        id={locationId}
        setId={setLocationId}
        {...getDialogProps(0)}
      />
      <TagSearch
        tags={tags}
        setTags={setTags}
        tagOptions={tagOptions}
        {...getDialogProps(1)}
      />
      <DateRangeSearch
        from={from}
        setFrom={setFrom}
        until={until}
        setUntil={setUntil}
        {...getDialogProps(2)}
      />
      <GuestSearch
        numGuests={numGuests}
        setNumGuests={setNumGuests}
        {...getDialogProps(3)}
      />
      <SearchButton onClick={performSearch} loading={loading} />
    </div>
  );
}
