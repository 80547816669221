import type { ReactNode, CSSProperties } from "react";
import cx from "classnames";

interface Props {
  size?:
    | "smaller"
    | "small"
    | "medium"
    | "normal"
    | "large"
    | "larger"
    | "jumbo";
  pre?: boolean;
  underline?: boolean;
  bold?: boolean;
  muted?: boolean;
  color?: "info" | "success" | "accent";
  children: ReactNode;
  style?: CSSProperties;
}

export function Text({
  children,
  size,
  pre = false,
  underline = false,
  bold = false,
  muted = false,
  color,
  style,
}: Props) {
  const className = cx({
    UIT: true,
    UITS1: size === "medium",
    UITS2: size === "smaller",
    UITS3: size === "small",
    UITS0: size === "normal",
    UITSL: size === "large",
    UITSLL: size === "larger",
    UITSLLL: size === "jumbo",
    UITP0: !pre,
    UITP1: pre,
    UITU0: !underline,
    UITU1: underline,
    UITW0: !bold,
    UITW1: bold,
    UITC0: true,
    UITC1: color === "info",
    UITC2: muted,
    UITC3: color === "success",
    UITC4: color === "accent",
  });

  return (
    <span className={className} style={style}>
      {children}
    </span>
  );
}
