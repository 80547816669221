interface TrackingEvent {
  category: string;
  action: string;
  label?: string;
  value?: string;
}

declare global {
  interface Window {
    gtag: any;
  }
}

export interface Tracker {
  trackEvent(event: TrackingEvent): void;
}

export const defaultTracker: Tracker = {
  trackEvent(event) {
    console.warn("[default analytics tracker]", "trackEvent", event); // eslint-disable-line
  },
};

type GaTrackingFn = (...args: any[]) => void;

const noop: GaTrackingFn = () => {
  // noop
};

function getGoogleAnalyticsTrackingFn(): GaTrackingFn {
  return typeof window.gtag === "function" ? window.gtag : noop;
}

function googleAnalyticsTrackEvent(event: TrackingEvent) {
  const gtag = getGoogleAnalyticsTrackingFn();
  gtag("event", event.action, {
    event_category: event.category,
    event_label: event.label,
    value: event.value,
  });
}

export const tracker: Tracker = {
  trackEvent(data) {
    googleAnalyticsTrackEvent(data);
  },
};
